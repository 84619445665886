/* App.css */

/* 
  If you're using the sidebar layout from LessonBuilder, 
  you typically wouldn't set the entire body to 
  display:flex or height:100vh here. Instead, keep it minimal. 
*/

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* White background */
  color: #333;
}

.container {
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
  text-align: center;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Header Section */
.header {
  margin-bottom: 20px;
}

.logo {
  max-width: 100px;
  height: auto;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  color: #2d5fcf;
  margin: 0;
}

/* Form Styling */
.form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

textarea {
  height: 80px;
  resize: none;
}

/* Generate Button Styling */
button.generate-button {
  width: 100%;
  padding: 12px;
  background: #2d5fcf;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
}

button.generate-button:hover {
  background: #2449a8;
  transform: scale(1.05);
}

.error {
  color: red;
  margin-top: 15px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  h1 {
    font-size: 20px;
  }
}
